import { render, staticRenderFns } from "./CheckBeforePackAdd.vue?vue&type=template&id=70a314ad&scoped=true&"
import script from "./CheckBeforePackAdd.vue?vue&type=script&lang=js&"
export * from "./CheckBeforePackAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a314ad",
  null
  
)

export default component.exports